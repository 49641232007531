<template>
  <div class="main">
    <nav-header></nav-header>
    <el-main class="home">
      <el-row type="flex" justify="center">
        <el-col :span="3"></el-col>
        <el-col :span="18" class="home-top">
          <el-row type="flex" justify="start" class="home-top-title-row">
            <el-col class="home-top-title">智能项目申报</el-col>
          </el-row>
          <el-row type="flex" justify="start">
            <el-col class="home-top-slogan">为企业提供更好更便捷的服务</el-col>
          </el-row>
          <el-row type="flex" justify="start" class="home-top-keyword-row">
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-zhuanzhu.png" alt="">
              <span>专注</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-zhuanye.png" alt="">
              <span>专业</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-fuwu.png" alt="">
              <span>服务</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-pinpai.png" alt="">
              <span>品牌</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" class="home-top-button-row">
            <el-col class="home-top-button">
              <el-button round @click="openLeaveContact">马上了解</el-button>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="3"></el-col>
      </el-row>
      <el-row class="home-second">
        <el-row>
          <el-col :span="24" class="home-second-bg"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-second-title">
          <el-col :span="3"></el-col>
          <el-col :span="21" style="align-items: center; display: flex;">
            <span class="home-little-title">热门政策</span><img src="../assets/image/version1/home-second-icon.png"
                                                                alt="" class="home-little-icon">
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-second-content">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex;">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item v-for="(item, i) in hotPolicies" :key="i" :name="String(i)" :title="item.title">
                <template #title>
                  <div class="el-collapse-item-title">{{ item.title }}</div>
                </template>
                <p>{{ item.content }}</p>
                <div style="display: flex;justify-content: space-between;align-items: center;padding-right: 15px;">
                  <span>发布时间：{{ item.publishTime }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;来源：{{ item.source }}</span>
                  <a :href="item.url" target="_blank" style="cursor:pointer;color: #006Db8;">查看详情</a>
                </div>
              </el-collapse-item>
              <div class="home-second-content-button">
                <el-button round >
                  <router-link style="color: #ffffff" to="/allZc">查看全部</router-link>
                </el-button>
              </div>
            </el-collapse>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-third">
        <el-row type="flex" justify="start" class="home-third-title">
          <el-col :span="3"></el-col>
          <el-col :span="21" style="align-items: center; display: flex;">
            <span class="home-little-title">服务流程</span><img src="../assets/image/version1/home-second-icon.png"
                                                                alt="" class="home-little-icon">
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-third-content">
          <el-col :span="3"></el-col>
          <el-col :span="6">
            <el-row type="flex" justify="start" v-for="(item, i) in serviceProcessLeft" :key="i"
                    class="home-third-content-row">
              <el-col :span="6" style="display: flex; justify-content: center; align-items: center;">
                <img class="home-third-content-no" :src="item.imageName" alt="">
              </el-col>
              <el-col :span="18" style="display: flex; justify-content: center; align-items: center;">
                <div>
                  <div class="home-third-content-title">{{ item.title }}</div>
                  <span class="home-third-content-keyword">{{ item.keyword }}</span>
                  <img class="home-third-content-arrow" src="../assets/image/version1/home-third-content-arrow.png"
                       alt="">
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <img class="home-third-content-m" src="../assets/image/version1/home-third-content-m.png" alt="">
          </el-col>
          <el-col :span="6">
            <el-row type="flex" justify="start" v-for="(item, i) in serviceProcessRight" :key="i"
                    class="home-third-content-row-right">
              <el-col :span="18" style="display: flex; justify-content: center; align-items: center;">
                <div style="text-align: right;">
                  <div class="home-third-content-title">{{ item.title }}</div>
                  <img class="home-third-content-arrow-right"
                       src="../assets/image/version1/home-third-content-arrow.png" alt="">
                  <span class="home-third-content-keyword">{{ item.keyword }}</span>
                </div>
              </el-col>
              <el-col :span="6" style="display: flex; justify-content: center; align-items: center;">
                <img class="home-third-content-no" :src="item.imageName" alt="">
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-fourth">
        <el-row type="flex" justify="start" class="home-fourth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">初创期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-fourth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="startUpPeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-fifth">
        <el-row type="flex" justify="start" class="home-fifth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">成长期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-fifth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="growthPeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-sixth">
        <el-row type="flex" justify="start" class="home-sixth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">成熟期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-sixth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="maturePeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>

    </el-main>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavHeader from './../components/navHeader'
import NavFooter from './../components/navFooter'

export default {
  name: "home",
  data() {
    return {
      activeName: '0',
      hotPolicies: [
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于组织开展2024年郑州市工程研究中心申报工作的通知",
          content: "各开发区、各区县（市）发展改革部门：\n" +
              "\n" +
              "为深入实施创新驱动发展战略，促进全市产业技术创新和成果转化，培育新质生产力，根据《郑州市工程研究中心管理办法》（郑发改高技〔2016〕559号）等有关规定，现将2024年郑州市工程研究中心申报工作有关事项通知如下：",
          source: "郑州市发改委",
          publishTime: "2024-11-13 09:48",
          url: "https://fgw.zhengzhou.gov.cn/notice/8809628.jhtml"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于2024年河南省工程技术研究中心申报受理情况的公示",
          content: "根据《河南省工程技术研究中心建设运行管理办法（暂行）》（豫科〔2024〕120号）和《关于组织申报2024年河南省工程技术研究中心的通知》（豫科实〔2024〕4号）要求，2024年河南省工程技术研究中心在线申报及主管部门审核于11月8日截止，全省共计649家企业通过“河南省科技管理信息系统”填报及主管部门审核推荐。经初步审查，符合通知中申报条件573家、不符合76家，现将受理情况予以公示，公示期5个工作日（自2024年11月13日至11月19日）。",
          source: "实验室与平台基地建设处",
          publishTime: "2024-11-13 08:34",
          url: "https://wap.kjt.henan.gov.cn/2024/11-12/3085412.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省科学技术厅 河南省财政厅 关于组织申报2025年度河南省重大创新项目的通知",
          content: "各省辖市科技局、财政局，济源示范区管委会科技、财政管理部门，郑州航空港经济综合实验区，各县（市）科技、财政主管部门，国家高新区、郑州经济技术开发区管委会，省直有关部门，各有关单位：",
          source: "科技项目统筹推进处",
          publishTime: "2024-11-07 16:21",
          url: "https://wap.kjt.henan.gov.cn/2024/11-07/3083264.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于组织申报2024年度郑州市科技成果转移转化补助的通知",
          content: "有关开发区、各市辖区科技主管部门，各技术合同登记机构，有关单位：\n" +
              "\n" +
              "根据《郑州市科技成果转移转化实施细则》（郑科规〔2023〕4 号）有关要求，结合年度预算安排，决定组织开展2024年度科技成果转移转化补助申报工作，现将有关事项通知如下：",
          source: "郑州市科学技术局成果处",
          publishTime: "2024-10-22 14:39:23",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/8759166.jhtml"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "工业和信息化部办公厅 国家发展改革委办公厅 财政部办公厅 国务院国资委办公厅 市场监管总局办公厅 国家数据局综合司关于开展2024年度智能工厂梯度培育行动的通知",
          content: "各省、自治区、直辖市及计划单列市、新疆生产建设兵团工业和信息化、发展改革、财政、国资、市场监管、数据主管部门，有关中央企业：\n" +
              "\n" +
              "贯彻落实国务院办公厅印发的《制造业数字化转型行动方案》，按照《“十四五”智能制造发展规划》任务部署，构建智能工厂、解决方案、标准体系“三位一体”工作体系，打造智能制造“升级版”，工业和信息化部、国家发展改革委、财政部、国务院国资委、市场监管总局、国家数据局决定联合开展2024年度智能工厂梯度培育行动。有关事项通知如下：",
          source: "装备工业一司",
          publishTime: "2024-10-28",
          url: "https://www.miit.gov.cn/jgsj/zbys/wjfb/art/2024/art_45f68e1298fb45f1b68a107641a4a78d.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "2024年度第二批河南省重点研发专项项目受理公示",
          content: "根据《河南省科技计划项目信息公开管理办法》《河南省科学技术厅 河南省财政厅关于申报2024年度第二批河南省重点研发专项的通知》要求，现对2024年度第二批省重点研发专项受理项目予以公示（详见附件），公示期为5个工作日，自2024年10月25日至2024年10月31日。",
          source: "科技项目统筹推进处",
          publishTime: "2024-10-25 18:17",
          url: "https://wap.kjt.henan.gov.cn/2024/10-25/3078486.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "2024年度河南省科学技术奖授奖建议项目公示",
          content: "2024年度河南省科学技术奖评审工作已经结束，共评出省自然科学奖、省技术发明奖、省科学技术进步奖授奖建议项目342项。根据《河南省科学技术奖励办法》的规定，自即日起将授奖建议项目(包括项目主要完成人和主要完成单位)在河南省科学技术厅网站（https://kjt.henan.gov.cn）和河南省科技奖励工作网（http://jl.hnkjt.gov.cn）同时公示。",
          source: "科技监督与诚信建设处",
          publishTime: "2024-10-23 18:36",
          url: "https://wap.kjt.henan.gov.cn/2024/10-23/3077517.html"
        },

        // {title:"豫工信联企业〔2022〕113号河南省工业和信息化厅河南省财政厅关于开展财政支持中小企业数字化转型试点工作的通知",content:"各开发区、区县（市）科技主管部门，各有关单位：为深入贯彻落实“创新驱动、科教兴市、人才强市”战略，推动我市创新平台建设，依据《郑州市工程技术研究中心和重点实验室建设与管理办法》（郑科规〔2021〕4号），市科技局决定开展2023年度郑州市工程技术研究中心和重点实验室申报工作。现将有关事宜通知如下。",publishTime:"2023-05-18 08:39:46",source:"河南省科学技术局资源处"}
      ],
      serviceProcessLeft: [
        {
          imageName: require("../assets/image/version1/home-third-content-1.png"),
          title: "确认需求",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-2.png"),
          title: "现场调研",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-3.png"),
          title: "项目规划",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-4.png"),
          title: "签订合同",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-5.png"),
          title: "项目会诊",
          keyword: "需求梳理 签订合同 紧急处理"
        }
      ],
      serviceProcessRight: [
        {
          imageName: require("../assets/image/version1/home-third-content-10.png"),
          title: "项目验收",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-9.png"),
          title: "跟踪服务",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-8.png"),
          title: "评审指导",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-7.png"),
          title: "申报项目",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-6.png"),
          title: "组织材料",
          keyword: "需求梳理 签订合同 紧急处理"
        },
      ],
      startUpPeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ],
      growthPeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ],
      maturePeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ]
    }
  },
  components: {
    NavHeader,
    NavFooter
  },
  created() {
  },
  mounted() {
  },
  methods: {
    homeTableHeaderRowClassName() {
      return "home-table-header-row"
    },
    homeTableHeaderCellClassName() {
      return "home-table-header-cell"
    },
    openLeaveContact() {
      this.$prompt('', '了解一下惠岑，开启业务新发展', {
        confirmButtonText: '免费咨询',
        showCancelButton: false,
        showClose: false,
        customClass: 'leave-contact-box',
        confirmButtonClass: 'leave-contact-box-button',
        inputPlaceholder: '请输入您的手机号',
        inputPattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
        inputErrorMessage: '手机号格式不正确'
      }).then(({value}) => {
        this.$message({
          type: 'success',
          message: '稍后我们将联系您: ' + value
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '请留下手机号，方便我们联系您'
        });
      });
    },
  }
}
</script>

<style scoped lang="scss">
.home {
  padding: 0px;

  .home-top {
    background-image: url("../assets/image/version1/home-top.png");
    background-position: center center;
    background-size: cover;
    height: vwf(590);
    text-align: center;

    img {
      width: vwf(30);
      height: vwf(30);
      vertical-align: middle;
    }

    span {
      font-size: vwf(30);
      font-weight: 500;
      color: #333333;
      margin-left: vwf(7);
    }

    .home-top-keyword {
      display: flex;
      align-items: center;
      margin: vwf(0) vwf(20);
    }

    .home-top-title {
      font-size: vwf(60);
      font-weight: 500;
      color: #333333;
      display: flex;
      padding-left: vwf(20);
    }

    .home-top-slogan {
      font-size: vwf(48);
      font-weight: 500;
      color: #333333;
      display: flex;
      padding-left: vwf(20);
    }

    .home-top-button {
      display: flex;
      padding-left: vwf(20);

      .el-button {
        width: vwf(240);
        height: vwf(80);
        background: #006DB8;
        border: 0px solid #DCDFE6;
        color: #FFFFFF;
        font-weight: 500;
        padding: vwf(18) vwf(60);
        font-size: vwf(30);
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
        border-radius: vwf(40);
      }
    }

    .home-top-title-row {
      margin-top: vwf(62);
    }

    .home-top-keyword-row {
      margin-top: vwf(60);
    }

    .home-top-button-row {
      margin-top: vwf(103);
    }
  }

  .home-second {
    height: vwf(937);

    .home-second-bg {
      background-image: url("../assets/image/version1/home-second-bg.png");
      background-position: center center;
      background-size: cover;
      height: vwf(360);
      text-align: center;
    }

    .home-second-title {
      position: absolute;
      top: vwf(100);
      width: 100%;
    }

    .home-second-content {
      position: absolute;
      top: vwf(180);
      width: 100%;

      .el-collapse {
        background: #FFFFFF;
        box-shadow: vwf(0) vwf(10) vwf(20) vwf(1) rgba(0, 0, 0, 0.16);
        border-radius: vwf(20) vwf(20) vwf(20) vwf(20);
        border: vwf(0);
        padding: vwf(30) vwf(38) vwf(30) vwf(38);
      }

      /deep/ .el-collapse-item__header {
        font-size: vwf(24);
        color: #333333;
        border-bottom: 0px;
        height: vwf(61);
        line-height: vwf(61);
      }

      .el-collapse-item-title {
        width: vwf(1234);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      /deep/ .el-collapse-item__content {
        p {
          padding-bottom: vwf(20);
          font-size: vwf(18);
          color: #666666;
          line-height: vwf(28);
        }

        span {
          padding-bottom: vwf(17);
          font-size: vwf(16);
          color: #999999;
          line-height: vwf(28);
        }
      }

      /deep/ .el-collapse-item__wrap {
        border-bottom: 0px;
      }
    }

    .home-second-content-button {
      display: flex;
      align-items: center;
      margin-top: vwf(50);

      .el-button {
        width: vwf(120);
        height: vwf(40);
        margin: 0 auto;
        background: #006DB8;
        border: 0px solid #DCDFE6;
        color: #FFFFFF;
        font-weight: 500;
        padding: vwf(8) vwf(28);
        font-size: vwf(16);
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
        border-radius: vwf(20);
      }
    }
  }

  .home-third {
    height: vwf(1000);
    background: #F7FAFD;

    .home-third-title {
      margin-top: vwf(100);
    }

    .home-third-content {
      margin-top: vwf(70);

      .home-third-content-row {
        width: 90%;
        height: vwf(116);
      }

      .home-third-content-row:hover {
        background: #006DB8;
        box-shadow: vwf(0) vwf(3) vwf(6) vwf(1) rgba(10, 65, 255, 0.16);
        border-radius: vwf(6) vwf(6) vwf(6) vwf(6);

        .home-third-content-title {
          color: #FFFFFF;
        }

        .home-third-content-keyword {
          color: #A1B6FF;
        }

        .home-third-content-no {
          filter: brightness(100);
        }

        .home-third-content-arrow {
          width: vwf(32);
          height: vwf(32);
          content: url("../assets/image/version1/home-third-content-arrow-s.png");
        }
      }

      .home-third-content-row-right {
        width: 90%;
        height: vwf(116);
        margin-left: 10%;
      }

      .home-third-content-row-right:hover {
        background: #006DB8;
        box-shadow: vwf(0) vwf(3) vwf(6) vwf(1) rgba(10, 65, 255, 0.16);
        border-radius: vwf(6) vwf(6) vwf(6) vwf(6);

        .home-third-content-title {
          color: #FFFFFF;
        }

        .home-third-content-keyword {
          color: #A1B6FF;
        }

        .home-third-content-no {
          filter: brightness(100);
        }

        .home-third-content-arrow-right {
          width: vwf(32);
          height: vwf(32);
          content: url("../assets/image/version1/home-third-content-arrow-s.png");
        }
      }

      .home-third-content-no {
        width: vwf(54);
        height: vwf(32);
      }

      .home-third-content-title {
        font-size: vwf(20);
        font-weight: blod;
        color: #333333;
      }

      .home-third-content-keyword {
        font-size: vwf(16);
        color: #BABECD;
      }

      .home-third-content-arrow {
        width: vwf(18);
        height: vwf(10);
        margin-left: vwf(40);
      }

      .home-third-content-arrow-right {
        width: vwf(18);
        height: vwf(10);
        margin-right: vwf(40);
        transform: rotate(180deg);
      }

      .home-third-content-m {
        width: vwf(468);
        height: vwf(580);
      }

    }
  }

  .home-fourth {
    .home-fourth-title {
      margin-top: vwf(100);
    }

    .home-fourth-content {
      margin-top: vwf(50);
    }
  }

  .home-fifth {
    .home-fifth-title {
      margin-top: vwf(100);
    }

    .home-fifth-content {
      margin-top: vwf(50);
    }
  }

  .home-sixth {
    margin-bottom: vwf(100);

    .home-sixth-title {
      margin-top: vwf(100);
    }

    .home-sixth-content {
      margin-top: vwf(50);
    }
  }

  .home-button-icon {
    width: vwf(12);
    height: vwf(12);
    vertical-align: middle;
    margin-left: vwf(6);
  }

  .home-look-all-button {
    .el-button {
      width: vwf(120);
      height: vwf(40);
      margin: 0 auto;
      background: #006DB8;
      border: 0px solid #DCDFE6;
      color: #FFFFFF;
      font-weight: 500;
      padding: vwf(8) vwf(19);
      font-size: vwf(16);
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
      border-radius: vwf(4);
    }
  }

  /deep/ .home-table-header-row {
    background-image: url(../assets/image/version1/home-table-header-bg.png);
    background-position: center center;
    background-size: cover;
  }

  /deep/ .home-table-header-cell {
    background-color: transparent;
  }


  .home-little-title {
    font-size: vwf(30);
    font-weight: bold;
    color: #333333;
  }

  .home-little-icon {
    width: vwf(24);
    height: vwf(24);
    vertical-align: middle;
  }

  // 覆盖elementUI
  .el-table {
    font-size: vwf(16);
    color: #333333;

    /deep/ .cell {
      line-height: 1.2 !important;
      white-space: pre-wrap;
    }

    /deep/ tbody tr:hover > td {
      background-color: #FFFFFF;
    }
  }

  /deep/ .el-table thead {
    color: #333333;
    font-weight: 400;
    font-size: vwf(20);
  }

  /deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #F7F7F7;
  }

  /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }

  // 不起作用
  // /deep/ .leave-contact-box {
  //   width: vwf(420);
  //   padding-bottom: vwf(10);
  //   border-radius: vwf(4);
  //   border: vwf(1) solid #EBEEF5;
  //   font-size: vwf(18);
  //   box-shadow: 0 vwf(2) vwf(12) 0 rgba(0,0,0,.1);
  // }

}
</style>
